'use client'

// React imports
import { useEffect } from 'react'

// Third party imports
import { useTranslations } from 'next-intl'

// Components
import SystemMessage from '~@Molecules/system-message/SystemMessage'

/**
 * Props interface for the Error component
 */
type Props = {
  /** The error object containing details about what went wrong */
  error: Error
  /** Function to reset/retry the failed operation */
  reset(): void
}

/**
 * Error boundary component that handles and displays errors that occur in the application
 *
 * @param {Props} props - Component props
 * @param {Error} props.error - The error object containing details about what went wrong
 * @param {Function} props.reset - Function to reset/retry the failed operation
 * @returns {JSX.Element} Error message display component
 */
export default function Error({ error, reset }: Props) {
  const t = useTranslations()

  // Log error details when component mounts or error changes
  useEffect(() => {
    const errorInfo = {
      message: error.message || 'Unknown error',
      timestamp: new Date().toISOString(),
    }

    console.error(errorInfo)
  }, [error])

  return (
    <SystemMessage
      desc={t('SystemMessage.error', { message: error.message })}
      status="error"
    />
  )
}
